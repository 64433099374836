import classNames from "lib/classNames";

interface Props {
  editing: boolean;
  settings: any;
  className: string;
  toggleTag: any;
  tags: string[];
}
export default function JournalTags({settings, editing, className, tags, toggleTag}:Props) {
  if(!settings || settings.length === 0) {
    return <></>;
  }

  return (
    <div className={className}>
      { editing ? (
        <>
          { settings.map((tag) => (
            <>
              <button
                onClick={() => toggleTag(tag.uid)}
                className={`text-[${tag.color}] bg-[${tag.color}]`}>
                <i className={classNames(
                  `${tag.icon} text-2xl`,
                  tags.indexOf(tag.uid) !== -1 ? "fas" : "far"
                )} />
              </button>
            </>
          ))}
        </>
      ) : (
        <>
          {
            settings.map((tag) => (
              <>
                {tags.indexOf(tag.uid) !== -1 && (
                  <span className={`text-[${tag.color}] bg-[${tag.color}]`}>
                    <i className={`fas ${tag.icon} text-2xl`} />
                  </span>
                )}
              </>
            ))
          }
        </>
      )}
    </div>
  );
}