import { gql } from "@apollo/client";
import JournalFragment from "./fragments/JournalFragment";

export default gql`
${JournalFragment}
mutation UpsertJournal($journal: journals_insert_input!) {
  insert_journals_one(object: $journal, on_conflict: {
      constraint: journals_userId_date_key,
      update_columns: [rating, notes, tags]
    }) {
    ...JournalFragment
  }
}
`;
