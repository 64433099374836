import { useEffect, useMemo, useState } from "react";
import JournalType from "types/JournalType";
import classNames from "lib/classNames";
import { useMutation } from "@apollo/client";
import upsertJournalQuery from "queries/journals/upsertJournalQuery";
import moment from "moment";
import { dayClass, dayClassText } from "lib/dayClass";
import { motion } from "framer-motion";
import useCurrentUser from "hooks/useCurrentUser";
import UserType from "types/UserType";
import JournalTags from "./JournalTags";

interface Props {
  journal: JournalType
  className?: String
  size?: String
  user: UserType;
}
export default function JournalDay({journal:originalJournal, className, size="large", user}:Props) {
  const { currentUser, setJournal:setJournalOnUser } = useCurrentUser();
  const [editing, setEditing] = useState(false);
  const [upsertJournal, { loading:saving }] = useMutation(upsertJournalQuery);
  const [journal, setJournal] = useState(originalJournal);

  useEffect(() => {
    setJournal(originalJournal)
  }, [originalJournal]);

  const dateFormat = useMemo(() => {
    const date = moment(journal.date);
    return date.format('YYYY.MM.DD');
  }, [journal.date]);

  const toggleEditing = () => {
    if(editing) {
      if(currentUser?.id) {
        upsertJournal({
          variables: {
            journal: {
              date: journal.date,
              rating: journal.rating,
              notes: journal.notes,
              tags: journal.tags || []
            }
          }
        }).then((data) => {
          setJournal(data.data.insert_journals_one);
          setJournalOnUser(data.data.insert_journals_one);
          setEditing(false);
        });
      } else {
        const newJournal = {
          ...journal,
          ...{
            date: journal.date,
            rating: journal.rating,
            notes: journal.notes,
            tags: journal.tags || []
          }
        }
        setJournal(newJournal);
        setJournalOnUser(newJournal);
        setEditing(false);
      }
    } else {
      setEditing(true);
    }
  }

  const setJournalProperty = (field, value) => {
    setJournal({
      ...journal,
      [field]: value
    });
  }

  const toggleTag = (uid) => {
    if(!uid || uid.length === 0) { return; }
    let tags = [...journal.tags];
    const tagIndex = tags.indexOf(uid);
    if(tagIndex=== -1) {
      tags.push(uid);
    } else {
      tags.splice(tagIndex, 1)
    }

    const newJournal = {
      ...journal,
      ...{ tags }
    }
    setJournal(newJournal);
  }

  return (
    <motion.article
      key={journal.date}
      className={classNames(
        className,
        "relative h-full",
    )}>
      <ol className={classNames(
        "absolute top-0 left-0 right-0 bottom-0 h-full",
        "border-black",
        size === "small" ? "border" : "border-2",
        "h-full w-full",
        "inline-block flex justify-center",
        editing? "border-dashed" : "border",
        dayClass(journal.rating)
      )}>
        
        { (editing || journal.notes?.length > 0) && (
          <li className="p-4 self-center text-center">
            <span className="label hidden">What did I do today?</span>
            { editing ? (
              <div className="text-right">
                <textarea
                  className="border border-gray-400 p-4"
                  rows={4}
                  onChange={(e) => setJournalProperty("notes", e.currentTarget.value)}
                >{journal.notes}</textarea>
                <button
                  type="submit"
                  onClick={toggleEditing}
                  className="p-1 py-0.5 bg-gray-200 rounded hover:bg-gray-300 text-sm"
                >Save</button>
              </div>
            ) : (
              <span className="leading-4 md:leading-6 tracking-tight inline-block" itemProp="description">{journal.notes}</span>
            )}
          </li>
        )}

        <li className="absolute bottom-0 left-0 transform -rotate-90 -translate-x-20 -translate-y-12 w-32 pt-1 pl-1 cursor-pointer">
          <span className="label hidden">Date</span>
          <button type="button" itemProp="duration" className="group flex items-center space-x-2" onClick={toggleEditing}>
            <i className="far fa-calendar text-gray-700"></i>
            <span className="text-xs md:text-sm">{dateFormat}</span>
            { saving && 
              <i className="fas fa-arrows-rotate text-black fa-spin"></i>
            }
            { !editing && 
              <span className="opacity-0 group-hover:opacity-100 transition-opacity">
                <i className="far fa-gear text-black"></i>
              </span>
            }
          </button>
        </li>

        <li className={classNames(
          dayClassText(journal.rating),
          "absolute top-0 left-0 transform -translate-x-5"
        )}>
          <span className="label hidden">Mood</span>
          { editing ? (
            <select
              className="border border-grey-700 transform -translate-x-5 text-xl"
              value={journal.rating}
              onChange={(e) => setJournalProperty("rating", e.currentTarget.value)}
            >
              <option value=""></option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
            </select>
          ) : (
            <span itemProp="rating" className="text-lg md:text-2xl">{journal.rating}</span>
          )}
        </li>


        {/* Top left */}
        <JournalTags
          settings={user.settings["topLeft"]}
          className="absolute top-0 left-0 transform -translate-y-9 py-1 w-24 h-8"
          editing={editing}
          toggleTag={toggleTag}
          tags={journal.tags}
        />

        {/* Inner Top left */}
        <JournalTags
          settings={user.settings["innerTopLeft"]}
          className="absolute top-0 left-0 px-2 py-1 w-24 h-8"
          editing={editing}
          toggleTag={toggleTag}
          tags={journal.tags}
        />

        {/* Top Right */}
        <JournalTags
          settings={user.settings["topRight"]}
          className="absolute top-0 right-0 transform -translate-y-8 py-1 w-24 h-8 text-right"
          editing={editing}
          toggleTag={toggleTag}
          tags={journal.tags}
        />

        {/* Inner Top Right */}
        <JournalTags
          settings={user.settings["innerTopRight"]}
          className="absolute top-0 right-0 lg:pr-1 lg:pt-2 w-24 h-8 text-right"
          editing={editing}
          toggleTag={toggleTag}
          tags={journal.tags}
        />

        {/* Right Top */}
        <JournalTags
          settings={user.settings["rightTop"]}
          className="absolute right-0 top-0 transform translate-x-8 translate-y-1 pl-2 w-8 h-24"
          editing={editing}
          toggleTag={toggleTag}
          tags={journal.tags}
        />

        {/* Right Bottom */}
        <JournalTags
          settings={user.settings["rightBottom"]}
          className="absolute right-0 bottom-0 transform translate-x-12 lg:translate-x-8 translate-y-1 lg:pl-2 w-8 h-24"
          editing={editing}
          toggleTag={toggleTag}
          tags={journal.tags}
        />

        {/* Innerbottom left */}
        <JournalTags
          settings={user.settings["innerBottomLeft"]}
          className="absolute bottom-0 left-0 px-1 lg:px-2 lg:py-1 w-24 h-8"
          editing={editing}
          toggleTag={toggleTag}
          tags={journal.tags}
        />

        {/* Innerbottom right */}
        <JournalTags
          settings={user.settings["innerBottomRight"]}
          className="absolute bottom-0 right-0 px-1 lg:p-1 w-24 h-8 text-right"
          editing={editing}
          toggleTag={toggleTag}
          tags={journal.tags}
        />

        {/* Bottom right */}
        <JournalTags
          settings={user.settings["bottomRight"]}
          className="absolute bottom-0 right-0 transform translate-y-6 lg:translate-y-8 px-1 lg:px-2 lg:py-1 w-24 h-8 text-right"
          editing={editing}
          toggleTag={toggleTag}
          tags={journal.tags}
        />

        {/* Bottom left */}
        <JournalTags
          settings={user.settings["leftBottom"]}
          className="absolute bottom-0 left-0 transform translate-y-6 lg:translate-y-8 px-1 lg:px-2 lg:py-1 w-24 h-8"
          editing={editing}
          toggleTag={toggleTag}
          tags={journal.tags}
        />

      </ol>
    </motion.article>
  );
}