export default `
  fragment JournalFragment on journals {
    id
    userId
    rating
    notes
    tags
    date
  }
`;
