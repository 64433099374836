import { useMemo, useState } from "react";
import JournalDay from "../journal/JournalDay";
import UserType from "types/UserType";
import moment from "moment";
import Link from "next/link";
import { motion, AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import JournalType from "types/JournalType";
import { layoutIdForJournal } from "lib/journalHelper";
import AvatarDropdown from "components/shared/nav/AvatarDropdown";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export default function SingleJournalPage({user, date}:{ user: UserType, date: moment.Moment}) {
  const router = useRouter();
  const [direction, setDirection] = useState(0);

  const journal = useMemo(() => {
    const d = date.format("YYYY-MM-DD");
    const j = user.journals.find((journal) => journal.date === d);
    return j ? j : { date, mDate: moment(date) } as unknown as JournalType;
  }, [user?.journals, date]);

  const isToday = useMemo(() => {
    let startTime = moment().startOf('day')
    return moment(date).isSameOrAfter(startTime)
  }, [date]);

  const previousDate = useMemo(() => {
    return moment(date).subtract(1, 'day').startOf('day').format("YYYY-MM-DD");
  }, [date]);

  const nextDate = useMemo(() => {
    return isToday ? null : moment(date).add(1, 'day').startOf('day').format("YYYY-MM-DD");
  }, [date, isToday]);

  const navigateTo = (route) => {
    router.push(route);
  }

  return (
    <div className="lg:h-screen">
      <div className="pt-2 pb-6 md:py-6 h-full flex flex-col">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex-shrink">
          <h2 className="text-2xl font-semibold text-gray-900 flex md:justify-center md:items-center">
            <Link href={`${user.demo ? "/demo" : ""}/day/${previousDate}`} passHref>
              <a className="mr-2 w-8" onClick={() => setDirection(-1)}>
                <i className="fas fa-angle-left text-black"></i>
              </a>
            </Link>

            <div className="w-64 flex space-x-4 items-center flex-shrink place-content-center">
              <span className="flex-shrink inline-block text-center space-x-2">
                <Link href={`${user.demo ? "/demo" : ""}/month/${moment(date).startOf('month').format('YYYY-MM-DD')}`} passHref>
                  <a className="underline hover:no-underline">
                    {date.format("MMMM")}
                  </a>
                </Link>
                <span>{date.format("D")}</span>
                <Link href={`${user.demo ? "/demo" : ""}/year/${moment(date).startOf('year').format('YYYY-MM-DD')}`} passHref>
                  <a className="underline hover:no-underline">
                    {date.format("YYYY")}
                  </a>
                </Link>
              </span>

              { !user.demo && (
                <div className="md:hidden">
                  <AvatarDropdown user={user} />
                </div>
              )}
            </div>

            { !isToday ? (
              <Link href={`${user.demo ? "/demo" : ""}/day/${nextDate}`} passHref>
                <a className="ml-2 w-8" onClick={() => setDirection(1)}>
                  <i className="fas fa-angle-right text-black"></i>
                </a>
              </Link>
            ) : (
              <span className="ml-2 w-8"></span>
            )}
          </h2>
        </div>

        <div className="mt-12 w-full md:max-w-xl mx-auto flex items-center justify-center h-full">
          <AnimatePresence initial={false} custom={direction}>
            <motion.div className="w-full w-64 h-64"
              key={journal.date}
              layoutId={layoutIdForJournal(journal)}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", duration: 0.5 },
                opacity: { duration: 0.2 }
              }}
              drag="x"
              dragSnapToOrigin={true}
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
    
                if (swipe < -swipeConfidenceThreshold) {
                  if(!isToday) {
                    setDirection(1);
                    navigateTo(`/day/${nextDate}`);
                  }
                } else if (swipe > swipeConfidenceThreshold) {
                  setDirection(-1);
                  navigateTo(`/day/${previousDate}`);
                }
              }}
            >
              <JournalDay journal={journal} user={user} />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}