export function dayClass(rating) {
  if(rating == 9) { return 'bg-green-300' }
  else if(rating == 8) { return 'bg-green-200' }
  else if(rating == 7) { return 'bg-green-100' }
  else if(rating == 6) { return 'bg-orange-100' }
  else if(rating == 5) { return 'bg-orange-200' }
  else if(rating == 4) { return 'bg-red-100' }
  else if(rating == 3) { return 'bg-red-200' }
  else if(rating == 2) { return 'bg-red-300' }
  else if(rating == 1) { return 'bg-red-400' }
}

export function hoverDayClass(rating) {
  if(rating == 9) { return 'hover:bg-green-400' }
  else if(rating == 8) { return 'hover:bg-green-300' }
  else if(rating == 7) { return 'hover:bg-green-200' }
  else if(rating == 6) { return 'hover:bg-orange-200' }
  else if(rating == 5) { return 'hover:bg-orange-300' }
  else if(rating == 4) { return 'hover:bg-red-200' }
  else if(rating == 3) { return 'hover:bg-red-300' }
  else if(rating == 2) { return 'hover:bg-red-400' }
  else if(rating == 1) { return 'hover:bg-red-500' }
  else { return 'bg-gray-50 hover:bg-gray-200' }
}


export function dayClassText(rating) {
  if(rating == 9) { return 'text-green-500' }
  if(rating == 8) { return 'text-green-400' }
  if(rating == 7) { return 'text-green-400' }
  if(rating == 6) { return 'text-orange-700' }
  if(rating == 5) { return 'text-orange-400' }
  if(rating == 4) { return 'text-red-400' }
  if(rating == 3) { return 'text-red-500' }
  if(rating == 2) { return 'text-red-600' }
  if(rating == 1) { return 'text-red-600' }
}

export function colOffsetForDate(mDate) {
  var start = mDate.day()
  if(start == 0) { start = 7; }
  return `col-start-${start}`
};
